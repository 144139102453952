import React from "react";
import { useIntl } from "react-intl";
import {
  CenterContainer,
  PageContainer,
  PageTitle,
  Subtitle,
} from "../styles/components";

export default function Coming() {
  const { formatMessage } = useIntl();
  return (
    <PageContainer>
      <CenterContainer>
        <PageTitle>{formatMessage({ id: "success.page.title" })}</PageTitle>
        <Subtitle>{formatMessage({ id: "success.page.subtitle" })}</Subtitle>
      </CenterContainer>
    </PageContainer>
  );
}
