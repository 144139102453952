import styled from "styled-components";
import { BACKGROUND_COLOR, PRIMARY_COLOR, SECONDARY_COLOR } from "./constants";

export const BottomContainer = styled.div`
  margin: 16px;
`;

export const MainTitle = styled.div`
  color: ${PRIMARY_COLOR};
  background-color: ${BACKGROUND_COLOR};
  font-size: 48px;
  font-weight: 100;
  letter-spacing: 4px;
  text-transform: uppercase;
  font-family: "RoxieRossa", sans-serif;
  padding-left: 8px;
  padding-right: 8px;
`;

export const PageTitle = styled.h1`
  color: ${SECONDARY_COLOR};
  font-size: 48px;
  font-weight: 100;
  letter-spacing: 4px;
  text-transform: uppercase;
  font-family: "RoxieRossa", sans-serif;
  margin-bottom: 0;
  background-color: ${BACKGROUND_COLOR};
`;

export const BottomContainerCentered = styled.div`
  margin: 8px;
  text-align: center;
  display: flex;
`;

export const FlexContainer = styled.div`
  display: flex;
`;

export const Subtitle = styled.h2`
  color: ${PRIMARY_COLOR};
  background-color: ${BACKGROUND_COLOR};
  margin: 0;
  font-size: 20px;
`;

export const PageContainer = styled.div`
  display: flex;
  height: calc(100vh - 92px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const CenterContainer = styled.div`
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  max-width: 600px;
  padding-left: 24px;
  padding-right: 24px;
`;

export const Olive1Container = styled.div`
  z-index: -1;
  top: 0;
  right: 0;
  position: absolute;
  width: 200px;

  @media screen and (max-width: 600px) {
    width: 130px;
  }
`;

export const Olive2Container = styled.div`
  position: absolute;
  width: 200px;
  z-index: -1;
  left: -20px;
  transform: rotate(174deg);
  bottom: 6px;

  @media screen and (max-width: 600px) {
    width: 170px;
  }
`;
