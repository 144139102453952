import React from "react";
import styled from "styled-components";
import { Guest } from "../types";
import Checkbox from "./StyledCheckbox";
import { PRIMARY_COLOR } from "../styles/constants";

type Props = {
  title: string;
  guests: Guest[];
  onChange: (guestName: string, checked: boolean) => void;
};

export default function GuestsConfirmation({ guests, title, onChange }: Props) {
  const handleOnChange = (guestName: string, checked: boolean) => {
    onChange(guestName, checked);
  };

  return (
    <Container>
      <GuestType>{title}</GuestType>
      {guests.map((guest) => {
        return (
          <GuestItem key={guest.name}>
            <h3>{guest.name}</h3>
            <Checkbox
              checked={guest.confirmedParty}
              onChange={(value: boolean) => handleOnChange(guest.name, value)}
            />
          </GuestItem>
        );
      })}
    </Container>
  );
}

const Container = styled.div`
  color: ${PRIMARY_COLOR};
  padding: 8px 16px;
  text-align: left;
  border-bottom: 1px solid ${PRIMARY_COLOR};
`;

const GuestType = styled.h2`
  color: ${PRIMARY_COLOR};
`;

const GuestItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
