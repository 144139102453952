import React from "react";
import { BrowserRouter, Routes, Route } from "react-router";
import Confirmation from "./pages/Confirmation";
import NotComing from "./pages/NotComing";
import Coming from "./pages/Coming";
import { IntlProvider } from "react-intl";
import messages_de from "./translations/de.json";
import messages_es from "./translations/es.json";
import "./fonts/Roxie-rossa.ttf";
import "./fonts/Roxie-rossa.otf";
import "./fonts/Roxie-rossa.woff";
import GlobalStyle from "./global-styles";
import { FontsStyle } from "./fonts-styles";
import { GuestsContextProvider } from "./context";
import LanguageSelector, {
  availableLanguages,
  Locales,
} from "./components/LanguageSelector";
import ComingSoon from "./pages/ComingSoon";

const messages: Record<Locales, any> = {
  de: messages_de,
  es: messages_es,
};

export default function App() {
  const browserLanguage = navigator.language.split(/[-_]/)[0] as Locales;
  const [selectedLanguage, setSelectedLanguage] = React.useState(
    availableLanguages.includes(browserLanguage) ? browserLanguage : "es"
  );

  console.log(navigator.language);

  return (
    <GuestsContextProvider>
      <IntlProvider
        locale={navigator.language}
        messages={messages[selectedLanguage]}
      >
        <GlobalStyle />
        <FontsStyle />
        <LanguageSelector
          onLanguageSelected={setSelectedLanguage}
          selectedLanguage={selectedLanguage}
        />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<ComingSoon />} />
            <Route path="/confirmation" element={<Confirmation />} />
            <Route path="/coming" element={<Coming />} />
            <Route path="/not-coming" element={<NotComing />} />
          </Routes>
        </BrowserRouter>
      </IntlProvider>
    </GuestsContextProvider>
  );
}
