import React, { createContext, ReactElement, useContext } from "react";
import { GuestRequest } from "./types";

type ContextType = {
  guestsRequest: GuestRequest;
  setGuestsRequest: (request: GuestRequest) => void;
};

const defaultContextValue: ContextType = {
  guestsRequest: {
    adults: [],
    kids: [],
    babies: [],
    music: "",
    other: "",
    confirmedGetTogether: false,
  },
  setGuestsRequest: () => {},
};
export const GuestsContext = createContext(defaultContextValue);

export const GuestsContextProvider = ({
  children,
}: {
  children: ReactElement;
}) => {
  const [guestsRequest, _setGuestsRequest] = React.useState(
    defaultContextValue.guestsRequest
  );

  const setGuestsRequest = (request: GuestRequest) => {
    _setGuestsRequest(request);
  };

  return (
    <GuestsContext.Provider value={{ guestsRequest, setGuestsRequest }}>
      {children}
    </GuestsContext.Provider>
  );
};

export const useGuestsContext = () => {
  const guestsContext = useContext(GuestsContext);

  if (!guestsContext) {
    throw new Error(
      "GuestsContext must be used within a GuestsContextProvider"
    );
  }

  return guestsContext;
};
