import React from "react";
import styled from "styled-components";
import { SwiperSlide, Swiper } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import ConfirmationSlide from "../components/Slides/ConfirmationSlide";
import FoodSlide from "../components/Slides/FoodSlide";
import GetTogetherSlide from "../components/Slides/GetTogetherSlide";
import MusicSlide from "../components/Slides/MusicSlide";
import { BACKGROUND_COLOR } from "../styles/constants";
import PinSlide from "../components/Slides/PinSlide";

export default function Confirmation() {
  return (
    <StyledSwiper
      spaceBetween={50}
      onSlideChange={() => console.log("slide change")}
    >
      <StyledSwiperSlide>
        <PinSlide />
      </StyledSwiperSlide>
      <StyledSwiperSlide>
        <ConfirmationSlide />
      </StyledSwiperSlide>
      <StyledSwiperSlide>
        <FoodSlide />
      </StyledSwiperSlide>
      <StyledSwiperSlide>
        <GetTogetherSlide />
      </StyledSwiperSlide>
      <StyledSwiperSlide>
        <MusicSlide />
      </StyledSwiperSlide>
    </StyledSwiper>
  );
}

const StyledSwiper = styled(Swiper)`
  width: 100%;
`;

const StyledSwiperSlide = styled(SwiperSlide)`
  text-align: center;
  font-size: 18px;
  background: ${BACKGROUND_COLOR};

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
`;
