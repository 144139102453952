import React from "react";
import styled from "styled-components";
import { PRIMARY_COLOR } from "../styles/constants";

export type Locales = "es" | "de";
export const availableLanguages: Locales[] = ["de", "es"];

type Props = {
  selectedLanguage: Locales;
  onLanguageSelected: (language: Locales) => void;
};
export default function LanguageSelector({
  selectedLanguage,
  onLanguageSelected,
}: Props) {
  return (
    <LanguageContainer>
      <p
        style={
          selectedLanguage === "de"
            ? { fontWeight: 800 }
            : { cursor: "pointer" }
        }
        onClick={() => onLanguageSelected("de")}
      >
        DE
      </p>
      <p>|</p>
      <p
        style={
          selectedLanguage === "es"
            ? { fontWeight: 800 }
            : { cursor: "pointer" }
        }
        onClick={() => onLanguageSelected("es")}
      >
        ES
      </p>
    </LanguageContainer>
  );
}

const LanguageContainer = styled.div`
  color: ${PRIMARY_COLOR};
  padding: 16px;
  width: 60px;
  justify-content: space-between;
  display: flex;
`;
