import React from "react";
import styled from "styled-components";
import { Guest, SpecialFood } from "../types";
import { PRIMARY_COLOR } from "../styles/constants";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useIntl } from "react-intl";
import { FoodOption } from "./Slides/FoodSlide";

type Props = {
  title: string;
  guests: Guest[];
  onChange: (guestName: string, foodOption: FoodOption) => void;
};

const animatedComponents = makeAnimated();

function GuestsItemFood({
  guest,
  onChange,
}: {
  guest: Guest;
  onChange: (guestName: string, foodOption: FoodOption) => void;
}) {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const { formatMessage } = useIntl();
  const options: { value: SpecialFood; label: string }[] = [
    { value: "all", label: formatMessage({ id: "food.page.all" }) },
    {
      value: "glutenfree",
      label: formatMessage({ id: "food.page.glutenfree" }),
    },
    {
      value: "vegetarian",
      label: formatMessage({ id: "food.page.vegetarian" }),
    },
    {
      value: "vegetarian_glutenfree",
      label: formatMessage({ id: "food.page.vegetarian_glutenfree" }),
    },
  ];

  return (
    <GuestItem onClick={() => setIsMenuOpen(!isMenuOpen)} key={guest.name}>
      <h3>{guest.name}</h3>
      <StyledSelect
        defaultValue={options[0]}
        onChange={(value) => onChange(guest.name, value as FoodOption)}
        menuIsOpen={isMenuOpen}
        components={animatedComponents}
        options={options as any}
      />
    </GuestItem>
  );
}

export default function GuestsFood({ guests, title, onChange }: Props) {
  return (
    <Container>
      <GuestType>{title}</GuestType>
      {guests.map((guest) => {
        return guest.confirmedParty ? (
          <GuestsItemFood key={guest.name} onChange={onChange} guest={guest} />
        ) : null;
      })}
    </Container>
  );
}
const StyledSelect = styled(Select)`
  border: 1px solid ${PRIMARY_COLOR};
  color: ${PRIMARY_COLOR};
  text-decoration: none;
  background-color: transparent;
`;

const Container = styled.div`
  color: ${PRIMARY_COLOR};
  padding: 8px 16px;
  text-align: left;
  border-bottom: 1px solid ${PRIMARY_COLOR};
`;

const GuestType = styled.h2`
  color: ${PRIMARY_COLOR};
`;

const GuestItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
