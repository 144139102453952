import React from "react";
import styled from "styled-components";
import { PRIMARY_COLOR } from "../styles/constants";

type Props = {
  text: string;
  onClick: () => void;
};
export default function StyledButton({ onClick, text }: Props) {
  return <Button onClick={onClick}>{text}</Button>;
}

const Button = styled.button`
  border: 1px solid ${PRIMARY_COLOR};
  color: ${PRIMARY_COLOR};
  background-color: transparent;
  transition: 0.3s;
  padding: 8px 16px;
  font: inherit;
  cursor: pointer;
  font-size: 24px;
  margin: 8px;
  min-width: 80px;

  &:hover {
    color: white;
    background-color: ${PRIMARY_COLOR};
  }
`;
