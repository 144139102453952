import React from "react";
import { useIntl } from "react-intl";
import {
  BottomContainer,
  Subtitle,
  CenterContainer,
  PageContainer,
  PageTitle,
} from "../../styles/components";

import StyledLink from "../StyledLink";
import styled from "styled-components";
import { PRIMARY_COLOR } from "../../styles/constants";
import { useGuestsContext } from "../../context";

export default function MusicSlide() {
  const { formatMessage } = useIntl();
  const { guestsRequest, setGuestsRequest } = useGuestsContext();

  const onMusicChange = (music: string) => {
    const newGuestsInfo = { ...guestsRequest };
    newGuestsInfo.music = music;
    setGuestsRequest(newGuestsInfo);
  };

  return (
    <PageContainer>
      <CenterContainer>
        <PageTitle>{formatMessage({ id: "music.page.title" })}</PageTitle>
        <Subtitle>{formatMessage({ id: "music.page.subtitle" })}</Subtitle>
        <StyledTextarea
          onChange={(e) => onMusicChange(e.currentTarget.value)}
        />
        <BottomContainer>
          <CenterContainer>
            <StyledLink route={"/coming"} messageId={"global.next.finish"} />
          </CenterContainer>
        </BottomContainer>
      </CenterContainer>
      <p>{JSON.stringify(guestsRequest)}</p>
    </PageContainer>
  );
}

const StyledTextarea = styled.textarea`
  color: ${PRIMARY_COLOR};
  background-color: transparent;
  border: 2px solid ${PRIMARY_COLOR};
  padding: 8px 16px;
  margin-top: 24px;
  text-align: left;
  border-bottom: 1px solid ${PRIMARY_COLOR};
  outline: none;
`;
