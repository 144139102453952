import { GuestRequest } from "./types";

const mockedGuests: GuestRequest = {
  adults: [
    {
      name: "Franzi",
      confirmedParty: false,
      foodType: "all",
    },
    {
      name: "Ole",
      confirmedParty: false,
      foodType: "all",
    },
  ],
  kids: [
    {
      name: "Majvi",
      confirmedParty: false,
      foodType: "all",
    },
  ],
  babies: [
    {
      name: "Malte",
      confirmedParty: false,
      foodType: "all",
    },
  ],
  confirmedGetTogether: false,
};

export async function saveGuestsData(guestsRequest: GuestRequest) {
  return Promise.resolve();
}

export async function getGuestsData(pin: number): Promise<GuestRequest> {
  return Promise.resolve(mockedGuests);
}
