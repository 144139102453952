import React from "react";
import { useIntl } from "react-intl";
import {
  BottomContainer,
  CenterContainer,
  PageTitle,
  Subtitle,
} from "../../styles/components";
import StyledButton from "../StyledButton";
import { useSwiper } from "swiper/react";
import { useGuestsContext } from "../../context";

export default function GetTogetherSlide() {
  const { formatMessage } = useIntl();
  const { guestsRequest, setGuestsRequest } = useGuestsContext();

  const onGetTogetherSelected = (value: boolean) => {
    const newGuestsInfo = { ...guestsRequest };
    newGuestsInfo.confirmedGetTogether = value;
    setGuestsRequest(newGuestsInfo);
    swiper.slideNext();
  };

  const swiper = useSwiper();
  return (
    <CenterContainer>
      <PageTitle>{formatMessage({ id: "gettogether.page.title" })}</PageTitle>
      <Subtitle>
        <p>{formatMessage({ id: "gettogether.page.subtitle" })}</p>
      </Subtitle>
      <BottomContainer>
        <StyledButton
          text={formatMessage({ id: "global.next.no" })}
          onClick={() => onGetTogetherSelected(false)}
        />
        <StyledButton
          text={formatMessage({ id: "global.next.yes" })}
          onClick={() => onGetTogetherSelected(true)}
        />
      </BottomContainer>
    </CenterContainer>
  );
}
