import React from "react";
import StyledButton from "../StyledButton";
import { useIntl } from "react-intl";
import { useSwiper } from "swiper/react";
import {
  BottomContainer,
  CenterContainer,
  PageTitle,
  Subtitle,
} from "../../styles/components";
import { useGuestsContext } from "../../context";
import { getGuestsData } from "../../api";
import PinInput from "react-pin-input";
import { PRIMARY_COLOR, SECONDARY_COLOR } from "../../styles/constants";

export default function PinSlide() {
  const { setGuestsRequest } = useGuestsContext();
  const { formatMessage } = useIntl();
  const [pin, setPin] = React.useState<undefined | number>();
  const swiper = useSwiper();

  const onSubmit = async () => {
    if (pin && String(pin).length === 6) {
      console.log("set data");
      const data = await getGuestsData(pin);
      setGuestsRequest(data);
      swiper.slideNext();
    }
  };

  return (
    <CenterContainer>
      <PageTitle>{formatMessage({ id: "pin.page.title" })}</PageTitle>
      <Subtitle>
        <p>{formatMessage({ id: "pin.page.subtitle" })}</p>
      </Subtitle>
      <PinInput
        length={6}
        onChange={(value, index) => {
          setPin(Number(value));
        }}
        type="numeric"
        inputMode="number"
        style={{ padding: "16px" }}
        inputStyle={{
          fontSize: "32px",
          margin: "8px",
          padding: "8px",
          borderColor: PRIMARY_COLOR,
        }}
        inputFocusStyle={{
          fontSize: "32px",
          margin: "8px",
          padding: "8px",
          borderColor: SECONDARY_COLOR,
        }}
        autoSelect={true}
        regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
      />
      <BottomContainer>
        <StyledButton
          text={formatMessage({ id: "global.next.button" })}
          onClick={() => onSubmit()}
        />
      </BottomContainer>
    </CenterContainer>
  );
}
