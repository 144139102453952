import { createGlobalStyle } from "styled-components";
import RoxieRossaTTF from "./fonts/Roxie-rossa.ttf";
import RoxieRossaWoff from "./fonts/Roxie-rossa.woff";

export const FontsStyle = createGlobalStyle`
 @font-face {
    font-family: "RoxieRossa";
    src:  url(${RoxieRossaTTF}) format("truetype");
          url(${RoxieRossaWoff}) format("woff");
    font-weight: normal;
    font-style: normal;
  }

`;
